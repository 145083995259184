<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">{{ t("headings.personnel") }}</h1>
    <breadcrumb class="mt-3 mb-4" />
    <div class="card">
      <custom-table
        :fields="fields"
        :items="personnel"
        :loading="waiting"
        :pagination="true"
        :perPage="10"
        :searchable="true"
        :searchFields="searchFields"
        :showHeader="true"
        sortBy="lastName"
        :sortFields="sortFields"
        @row-click="
          (id) =>
            id == user.id
              ? router.push('/profile')
              : router.push(`/personnel/${id}`)
        "
      >
        <template #header>
          <span class="flex justify-end"
            ><form-button
              :command="() => router.push('/personnel/create')"
              :icon="PlusIcon"
              id="header-user-create-button"
              label="create_user"
              :textVariant="company.primaryText"
              type="button"
              :variant="company.primary"
          /></span>
        </template>
        <template #cell(lastName)="{ item }">
          <div class="flex items-center">
            <img
              v-if="item.avatar"
              class="h-10 w-10 rounded-full object-cover"
              :src="getAvatar(item)"
            />
            <div
              v-else
              class="aspect-square flex h-10 w-10 items-center justify-center rounded-full bg-slate-300"
            >
              <UserIcon class="h-3/4 w-3/4 text-white" />
            </div>
            <span class="ml-5">{{ item.lastName }} {{ item.firstName }}</span>
          </div>
        </template>
        <template #cell(roleId)="{ value }">
          <div class="flex gap-2">
            <div v-for="role in getRoles(value, roles)" :key="role.id">
              {{ t(`roles.${role.label}`) }}
            </div>
          </div>
        </template>
        <template #cell(status)="{ value }">
          {{ t(`status.${value}`) }}
        </template>
      </custom-table>
    </div>
  </div>
</template>

<script>
import { UserIcon } from "@heroicons/vue/24/outline";
import { PlusIcon } from "@heroicons/vue/24/solid";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import FormButton from "../components/form/FormButton.vue";
import CustomTable from "../components/table/CustomTable.vue";
import { getAvatar } from "../utils/imageUtils";
import { getRoles } from "../utils/roleUtils";
export default {
  components: { Breadcrumb, CustomTable, FormButton, PlusIcon, UserIcon },
  setup() {
    const router = useRouter();
    const store = useStore();
    const { t } = useI18n();

    const company = computed(() => store.state.company.company);
    const personnel = computed(() => store.state.user.personnel);
    const user = computed(() => store.state.authentication.user);
    const roles = computed(() => store.state.role.roles);

    const waiting = computed(() => {
      if (store.state.company.waiting) return true;
      if (store.state.user.waiting) return true;
      if (store.state.role.waiting) return true;
      if (store.state.authentication.waiting) return true;
      return false;
    });

    const fields = ref([
      {
        key: "lastName",
        label: "name",
      },
      {
        key: "roleId",
        label: "role",
      },
      {
        key: "email",
        label: "email",
      },
      {
        key: "phone",
        label: "phone",
      },
      {
        key: "status",
        label: "status",
      },
    ]);

    const searchFields = ref(["email", "firstName", "lastName", "phone"]);
    const sortFields = ref(["email", "roleId", "lastName", "phone"]);

    onMounted(() => {
      if (!personnel.value.length) {
        store.dispatch("user/getPersonnel", user.value.companyId);
      }
      if (!roles.value.length) {
        store.dispatch("role/getRoles");
      }
    });

    return {
      company,
      fields,
      getAvatar,
      getRoles,
      personnel,
      PlusIcon,
      roles,
      router,
      searchFields,
      sortFields,
      t,
      user,
      waiting,
    };
  },
};
</script>
